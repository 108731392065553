import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faEye } from "@fortawesome/free-solid-svg-icons";

import { useGetUsersQuery } from "./usersApiSlice";
import { useNavigate } from "react-router-dom";
import { TextField, Grid, Button } from "@mui/material";
import { Tooltip } from "react-tooltip";
import useAuth from "../../hooks/useAuth";


const columns = [
  { id: "username", label: "ユーザー名", minWidth: 170 },
  {
    id: "institute",
    label: "所属",
    minWidth: 170,
  },
  {
    id: "role",
    label: "ロール",
    minWidth: 170,
  },
  {
    id: "edit",
    label: "詳細",
    minWidth: 50,
    maxWidth: 70,
  },
];

function createData(obj) {
  return {
    id: obj?.id,
    username: obj?.username,
    institute: obj?.institute,
    role: obj?.role,
    active: obj?.active,
  };
}

export default function UsersList() {
  const { isManager, userInstitute, userID } = useAuth();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchUsername, setSearchUsername] = React.useState("");
  const [searchInstitute, setSearchInstitute] = React.useState("");

  const navigate = useNavigate();

  const handleEdit = (userId) => navigate(`/dash/users/edit/${userId}`);
  const handleView = (userId) => navigate(`/dash/users/view/${userId}`);

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUsersQuery("usersList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  let content;

  if (isLoading) content = <p>Loading...</p>;

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { ids } = users;
    let entities = Object.values(users?.entities);
    let rows = entities?.map((entity) => createData(entity));

    if (isManager) {
      rows = rows.filter(
        (row) =>
          ((row.role !== "アドミン" &&
          row.role !== "マネージャー") || row.id === userID) &&
          row.institute === userInstitute
      );
    }

    // Sort by username alphabetically and then by active status
    rows.sort((a, b) => {
      if (a.active === b.active) {
        return a.username.localeCompare(b.username);
      }
      return a.active ? -1 : 1; // Active users first
    });

    const filteredRows = rows.filter((row) => {
      const matchesUsername =
        row.username?.toLowerCase().includes(searchUsername.toLowerCase()) ??
        false;
      const matchesInstitute =
        searchInstitute === ""
          ? true
          : row.institute
              ?.toLowerCase()
              .includes(searchInstitute.toLowerCase()) ?? false;
      return matchesUsername && matchesInstitute;
    });

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (
      event
    ) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    content = (
      <>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ marginBottom: "1rem" }}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="outlined-search-username"
              label="ユーザー名で検索"
              type="search"
              size="small"
              value={searchUsername}
              onChange={(e) => {
                setSearchUsername(e.target.value);
                setPage(0); // Reset page to 0 when search term changes
              }}
            />
          </Grid>
          {!isManager && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="outlined-search-institute"
                label="所属で検索"
                type="search"
                size="small"
                value={searchInstitute}
                onChange={(e) => {
                  setSearchInstitute(e.target.value);
                  setPage(0); // Reset page to 0 when search term changes
                }}
              />
            </Grid>
          )}
        </Grid>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 900 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        className={!row.active ? "inactive-row" : ""}
                      >
                        {columns.slice(0, -1).map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                        <TableCell align="left">
                          <button
                            className="list__view-button"
                            onClick={() => handleView(row.id)}
                            data-tooltip-id="view-tooltip"
                            data-tooltip-content="ユーザーの成績を見る"
                            data-tooltip-place="left"
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                          <button
                            className="list__edit-button"
                            onClick={() => handleEdit(row.id)}
                            data-tooltip-id="edit-tooltip"
                            data-tooltip-content="ユーザーを編集する"
                            data-tooltip-place="left"
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </button>
                          <Tooltip id="edit-tooltip" />
                          <Tooltip id="view-tooltip" />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </>
    );
  }
  return content;
}
