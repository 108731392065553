import React from "react";
import * as XLSX from "xlsx";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  useGetQuestionsQuery,
  useAddNewQuestionMutation,
  useDeleteQuestionMutation,
} from "./questionsApiSlice";

const pageCSS = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function ImportQuestions() {
  let { data: questions, isSuccess: isSuccessQuestions } = useGetQuestionsQuery(
    "questionsList",
    {
      pollingInterval: 60000,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const [addNewQuestion, { isLoading, isSuccess, isError, error }] =
    useAddNewQuestionMutation();

  const [
    deleteQuestion,
    {
      isLoading: isDeleting,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteQuestionMutation();

  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [finished, setFinished] = React.useState(false);
  const [failedQuestions, setFailedQuestions] = React.useState([]);
  const [activeQuestions, setActiveQuestions] = React.useState([]);

  // When questions are successfully fetched, update activeQuestions state
  React.useEffect(() => {
    if (isSuccessQuestions) {
      const activeQuestionsList = Object.values(questions.entities).filter(
        (q) => q.active
      );
      setActiveQuestions(activeQuestionsList);
    }
  }, [isSuccessQuestions, questions]);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      setLoading(true);
      setFailedQuestions([]);
      const workbook = XLSX.read(event.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      let sheetData = XLSX.utils.sheet_to_json(sheet);

      sheetData = sheetData.filter((row) => row["問題"]);
      let questions = sheetData.map((row) => {
        const answers = row["選択肢"].split("\r\n");
        let returnedContent = {
          id: row["No."],
          question: row["問題"],
          domain: row["分野"],
          number_of_answers: answers.length,
          multiple_answer_p: row["正答"].toString().includes(","),
          correct_answer: row["正答"].toString(),
          difficulty: row["困難度"],
          level: row["レベル"],
          subLevel: row["サブレベル"],
          cat: "1",
          active: row["有効"],
          new_question: row["新問題"] === 1 ? true : false,
          shikibetsu: row["識別度"],
        };
        for (let i = 1; i <= answers.length; i++) {
          returnedContent[`answer${i}`] = answers[i - 1];
        }
        return returnedContent;
      });
      setData(questions);
      for (let i = 0; i < questions.length; i++) {
        await addNewQuestion(questions[i]).then((res) => {
          if (res && res.error && res.error.status == 400) {
            setFailedQuestions((prev) => [...prev, questions[i]]);
          } else if (questions[i].active) {
            setActiveQuestions((prev) => [...prev, questions[i]]);
          }
        });
      }
      setLoading(false);
      setFinished(true);
    };

    reader.readAsBinaryString(file);
  };

  const handleButtonClick = async (id) => {
    await deleteQuestion({ id: id }).then((res) => {
      if (res && res.data && res.data.status === true) {
        setActiveQuestions((prev) => prev.filter((q) => q._id !== id));
      }
    });
  };

  return (
    <>
      {!isLoading && (
        <div className="input-container">
          <input
            type="file"
            id="fileInput"
            className="questions_import"
            onChange={handleFileUpload}
            style={{ display: "none" }} // Hide the default file input
          />
          <label htmlFor="fileInput" className="custom-file-upload">
            ファイルを選択
          </label>
        </div>
      )}
      <div style={pageCSS}>
        {!finished && !isLoading && !loading ? (
          <>
            {activeQuestions && activeQuestions.length > 0 && (
              <div className="active-questions-container">
                <h2>有効な質問</h2>
                <ul className="active-questions-list">
                  {activeQuestions.map((question, index) => (
                    <li key={index} className="active-question-item">
                      <span>ID: {question.id}</span>
                      <button onClick={() => handleButtonClick(question._id)}>
                        無効
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        ) : !finished ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress size="6rem" />
          </Box>
        ) : null}
        {finished && (
          <div>
            <h1>質問がインポートされました。</h1>
            {failedQuestions.length > 0 && (
              <div className="failed-questions-container">
                <h2>インポートに失敗した質問</h2>
                <ul className="failed-questions-list">
                  {failedQuestions.map((question, index) => (
                    <li key={index} className="failed-question-item">
                      ID: {question.id}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default ImportQuestions;
