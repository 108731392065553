import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  Typography,
} from "@mui/material";
import { useGetQuestionsQuery } from "./questionsApiSlice"; // Adjust the import as needed
import "./QuestionsList.css";

const columns = [
  { id: "id", label: "ID", minWidth: 100 },
  { id: "number_of_answers", label: "回答数", minWidth: 150 },
  { id: "correct_answer", label: "正答", minWidth: 150 },
  { id: "new_question", label: "新問題", minWidth: 150 },
  { id: "view", label: "詳細", minWidth: 100 },
];

function createData(obj) {
  return {
    id: obj?.id,
    number_of_answers: obj?.number_of_answers,
    correct_answer: obj?.correct_answer,
    question: obj?.question,
    answers: [
      obj?.answer1,
      obj?.answer2,
      obj?.answer3,
      obj?.answer4,
      obj?.answer5,
      obj?.answer6,
    ].filter(Boolean),
    multiple_answer_p: obj?.multiple_answer_p,
    active: obj?.active,
    difficulty: obj?.difficulty,
    level: obj?.level,
    subLevel: obj?.subLevel,
    cat: obj?.cat,
    new_question: obj?.new_question,
    shibiketsu: obj?.shibiketsu,
  };
}

export default function QuestionsList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (question) => {
    setSelectedQuestion(question);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedQuestion(null);
    setOpenModal(false);
  };

  const {
    data: questions,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetQuestionsQuery("questionsList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  let content;

  if (isLoading) content = <p>読み込み中...</p>;

  if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    let entities = Object.values(questions?.entities);
    let rows = entities?.map((entity) => createData(entity));

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    content = (
      <>
        <div className="table-root">
          <Paper
            sx={{
            //   width: "100%",
              overflow: "hidden",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
            }}
          >
            <TableContainer sx={{ flexGrow: 1, overflowY: "auto" }}>
              <Table stickyHeader aria-label="questions table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        style={{ minWidth: column.minWidth }}
                        className="table-header"
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row) => (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.number_of_answers}</TableCell>
                        <TableCell>{row.correct_answer}</TableCell>
                        <TableCell>{row.new_question ? "はい" : "いいえ"}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleOpenModal(row)}
                          >
                            表示
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={rows?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ flexShrink: 0 }}
            />
          </Paper>
        </div>

        {/* Modal Component */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          aria-labelledby="question-modal-title"
          aria-describedby="question-modal-description"
        >
          <Fade in={openModal}>
            <Box className="modal-content">
              {selectedQuestion && (
                <>
                  <Typography
                    id="question-modal-title"
                    variant="h5"
                    component="h2"
                    className="modal-title"
                  >
                    設問の詳細
                  </Typography>
                  <Typography
                    id="question-modal-description"
                    className="modal-question"
                  >
                    <strong>設問:</strong> {selectedQuestion.question}
                  </Typography>
                  <Typography className="modal-answers-title">
                    <strong>選択肢:</strong>
                  </Typography>
                  <ul className="modal-answers-list">
                    {selectedQuestion.answers.map((answer, index) => (
                      <li key={index} className="modal-answer-item">
                        {answer}
                      </li>
                    ))}
                  </ul>
                  <Typography className="modal-correct-answer">
                    <strong>正答:</strong>{" "}
                    {selectedQuestion.correct_answer}
                  </Typography>
                  <Typography className="modal-correct-answer">
                    <strong>新問題:</strong>{" "}
                    {selectedQuestion.new_question ? "はい" : "いいえ"}
                  </Typography>
                  {/* Add more details as needed */}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCloseModal}
                    className="modal-close-button"
                  >
                    閉じる
                  </Button>
                </>
              )}
            </Box>
          </Fade>
        </Modal>
      </>
    );
  }

  return <div className="table-root">{content}</div>;
}
