import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Public.css"; // Assuming you're using a separate CSS file for styling

const Public = () => {
  const navigate = useNavigate();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const textContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (textContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          textContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          // The '-10' is a small buffer to ensure that the user scrolled to the end
          setIsButtonEnabled(true);
        }
      }
    };

    const checkIfScrollbarIsPresent = () => {
      if (textContainerRef.current) {
        const { scrollHeight, clientHeight } = textContainerRef.current;
        if (scrollHeight <= clientHeight) {
          setIsButtonEnabled(true);
        } else {
          setIsButtonEnabled(false);
        }
      }
    };

    const container = textContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      checkIfScrollbarIsPresent(); // Check initially if the scrollbar is present
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleButtonClick = () => {
    if (isButtonEnabled) {
      navigate("/login");
    }
  };

  return (
    <div className="public-wrapper">
      <section className="public">
        <header className="public__header">
          <div className="logo-title">
            <img src="/KAKENHIlogoS.jpg" alt="Logo" className="logo" />
            <h1>ジェネラリスト看護師の臨床実践能力判定テスト</h1>
          </div>
        </header>
        <main className="public__main">
          <div className="public__text-container" ref={textContainerRef}>
            <p>
              本システムは、ジェネラリスト看護師の臨床実践能力(クリニカルラダー)を判定するツールとして開発
              されました。利用は無料ですが、通信費は利用者負担となります。利用者が臨床実践能力に応じた問題
              に解答することでクリニカルラダーIまたはIIまたはIII以上を判定しますが、本システムはまだ研究段
              階であり(研究テーマ「地域包括ケア時代におけるジェネラリスト看護師の臨床実践能力評価システムの
              開発」)、判定結果が実際の臨床実践能力とは異なる場合があることをご了承ください。
            </p>
            <p>
              また、本システムは地域における看護職の人事交流や異動の際の実践能力評価に広く活用されるように
              なり、看護職のキャリア継続の支援ならびに病院から地域への看護職の流動化に貢献することを目指し
              ます。より汎用性があり精度の高い判定システムへ改善するため、本研究終了後も利用者の解答が分析
              され利用されること、さらに研究成果として学会等で発表されることをご了承ください。
            </p>
            <p>
              ご利用にあたって氏名等の個人情報をご入力いただくことはなく、個人が特定されることはありません
              ので、ご安心ください。所要時間は、利用者の臨床実践能力により判定までの出題数が異なるため15~
              30分程度となります。途中で解答を中止することも可能ですが、臨床実践能力の判定結果は最後まで解
              答した場合にのみ表示されます。途中で中止しても判定結果が得られないこと以外の不利益を被ること
              はございません。途中で中止した場合は解答が分析されることもありません。ただし、最後まで解答さ
              れると解答がデータベースに登録されます。この場合は無記名であるため分析から除外することはでき
              ませんので、ご了承ください。
            </p>
            <p>
              ご不明な点や質問などがありましたら、下記連絡先にお問い合わせください。
            </p>
            <address>
              名古屋大学医学部附属病院 卒後臨床研修・キャリア形成支援センター
              <br />
              看護キャリア支援室 室長／教授 秋山智弥
              <br />
              名古屋市昭和区鶴舞町 65
              <br />
              TEL：052-744-2934
              <br />
              E-mail：akiyama.tomoya.b4@f.mail.nagoya-u.ac.jp
            </address>
          </div>
          <button
            className={`start-button-public ${
              isButtonEnabled ? "enabled" : "disabled"
            }`}
            onClick={handleButtonClick}
            disabled={!isButtonEnabled}
          >
            上記内容に同意して次へ進む
          </button>
        </main>
      </section>
    </div>
  );
};

export default Public;
