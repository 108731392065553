import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { selectUserById } from "../users/usersApiSlice";
import { useNavigate } from "react-router-dom";
import { selectQuizFinished } from "./authSlice";
import { useDownloadQuizExcelMutation } from "../quiz/quizSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Welcome = () => {
  const { userID, username, isManager, isAdmin } = useAuth();
  const user = useSelector((state) => selectUserById(state, userID));
  const quizCompleted = useSelector(selectQuizFinished);
  const navigate = useNavigate();

  const [downloadQuizExcel, { isLoading }] = useDownloadQuizExcelMutation();
  let loadingBar = null;
  if(isLoading) {
    loadingBar = null;
  } else {
    loadingBar = (
      <Box sx={{ display: "flex" }}>
        <CircularProgress size="6rem" />
      </Box>
    )
  }

  // useEffect(() => {
  //     // Only navigate if user is defined and personal_info_completed is false
  //     if (user && !isManager && !isAdmin) {
  //         if (!user.isUniversal && !user.personal_info_completed) {
  //             navigate(`/profile/${userID}`);
  //         } else if (!user.experience_info_completed) {
  //             navigate(`/profile/experience`);
  //         }
  //     }
  // }, [user, navigate, userID]);

  // If user is undefined or we're still checking if we need to navigate, don't render anything
  // if (!user || (user && (!isAdmin && ((!user.isUniversal && !user.personal_info_completed) || !user.experience_info_completed)))) {
  //     return null; // Don't render anything while deciding whether to navigate
  // }
  const handleButtonClick = async () => {
    try {
      await downloadQuizExcel(userID).unwrap();
    } catch (error) {
      console.error("Failed to download the Excel file:", error);
    }
  };

  const content = (
    <section className="welcome">
      <h1 className="welcome__name">{username}さん、ようこそ!</h1>
      <div className="welcome__functions">
        {(isManager || isAdmin) && (
          <div className="welcome__functions__buttons">
            <p>
              <Link to="/dash/users">全てのユーザーを表示する</Link>
            </p>
            <p>
              <Link to="/dash/users/new">ユーザーを追加する</Link>
            </p>
            <p>
              <Link to="/dash/questions/import">クイズの質問をインポート</Link>
            </p>
            <p>
              <Link to="/quiz">テストを開始</Link>
            </p>
            <p>
              <Link to="/dash/questionsList">設問リスト</Link>
            </p>
            <p>
              {/* Button that calls handleButtonClick when clicked */}
              <button
                className="welcome__functions__buttons__button"
                onClick={handleButtonClick}
              >
                過去のテストをダウンロード
              </button>
            </p>
            {isLoading && <>{loadingBar}</>}
          </div>
        )}
        {!quizCompleted && !isManager && !isAdmin && (
          <div className="welcome__functions__buttons">
            <p>
              <Link to="/quiz">テストを開始</Link>
            </p>
          </div>
        )}
        {isLoading && (
          <Box sx={{ display: "flex" }}>
            <CircularProgress size="6rem" />
          </Box>
        )}
      </div>
    </section>
  );

  return content;
};

export default Welcome;
