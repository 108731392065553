import * as React from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useUpdateUserMutation } from "./usersApiSlice";

export default function Questionnaire({ startQuizCallback }) {
  const defaultTheme = createTheme();

  const { userID } = useAuth();
  const [updateUser] = useUpdateUserMutation();
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState({
    experience_total: null,
    experience_current_hospital: null,
    position: null,
    institution_type: null,
    number_of_beds: null,
    institution_ladder: null,
    institution_ladder_five_level: null,
    institution_ladder_four_level: null,
    clinical_ladder: null,
  });

  const [currentStep, setCurrentStep] = React.useState(1);

  const handleRadioChange = (event, field) => {
    setUserData({
      ...userData,
      [field]: parseInt(event.target.value),
    });
  };

  const handleNext = () => {
    if (currentStep === 6) {
      // Handle conditional step logic
      if (userData.institution_ladder === 1) {
        setCurrentStep(7); // Go to 6a (which is step 7)
      } else if (userData.institution_ladder === 2) {
        setCurrentStep(8); // Go to 6b (which is step 8)
      } else {
        setCurrentStep(9); // Go directly to question 7 (which is step 9)
      }
    } else if (currentStep === 7 || currentStep === 8) {
      // After 6a or 6b, always go to question 7
      setCurrentStep(9);
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep === 7) {
      // From 6a back to 6
      setCurrentStep(6);
    } else if (currentStep === 8) {
      // From 6b back to 6
      setCurrentStep(6);
    } else if (currentStep === 9) {
      // From 7 back to 6a or 6b based on the institution_ladder value
      if (userData.institution_ladder === 1) {
        setCurrentStep(7); // Go back to 6a
      } else if (userData.institution_ladder === 2) {
        setCurrentStep(8); // Go back to 6b
      } else {
        setCurrentStep(6); // Go back to 6 if "その他"
      }
    } else {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = [
      "experience_total",
      "experience_current_hospital",
      "position",
      "institution_type",
      "number_of_beds",
      "institution_ladder",
      "clinical_ladder",
    ];

    for (let field of requiredFields) {
      if (userData[field] === null) {
        alert("すべての質問に答えてください。");
        return;
      }
    }

    if (userData.institution_ladder === 1 && userData.institution_ladder_five_level === null) {
      alert("設問6aに答えてください。");
      return;
    }

    if (userData.institution_ladder === 2 && userData.institution_ladder_four_level === null) {
      alert("設問6bに答えてください。");
      return;
    }

    const submissionData = {
      ...userData,
      id: userID,
    };

    try {
      await startQuizCallback(submissionData);
    } catch (err) {
      console.log(err);
    }
  };

  const steps = [
    {
      label: "設問#1 あなたの看護師としての実務経験年数を1つ選択してください。",
      name: "experience_total",
      options: [
        "1 年未満",
        "1 年以上～2 年未満",
        "2 年以上～3 年未満",
        "3 年以上～5 年未満",
        "5 年以上～10 年未満",
        "10 年以上～20 年未満",
        "20 年以上",
      ],
    },
    {
      label: "設問#2 あなたの現施設で実務経験年数を1つ選択してください。",
      name: "experience_current_hospital",
      options: [
        "1 年未満",
        "1 年以上～2 年未満",
        "2 年以上～3 年未満",
        "3 年以上～5 年未満",
        "5 年以上～10 年未満",
        "10 年以上～20 年未満",
        "20 年以上",
      ],
    },
    {
      label: "設問#3 あなたの役職を1つ選択してください。",
      name: "position",
      options: [
        "スタッフ（役職なし）",
        "主任相当",
        "師長相当",
        "看護副部長相当以上",
      ],
    },
    {
      label: "設問#4 所属施設の種別を1つ選択してください。",
      name: "institution_type",
      options: [
        "病院（主に急性期）",
        "病院（主に回復期）",
        "病院（主に慢性期）",
        "診療所",
        "助産所",
        "訪問看護ステーション",
        "介護保険施設等",
        "社会福祉施設",
        "看護師等学校養成所又は研究機関",
        "その他",
      ],
    },
    {
      label: "設問#5 所属施設の規模（ベッド数）を1つ選択してください。",
      name: "number_of_beds",
      options: [
        "なし",
        "19 ベッド以下",
        "20～99 ベッド",
        "100～199 ベッド",
        "200～499 ベッド",
        "500 ベッド以上",
      ],
    },
    {
      label: "設問#6 あなたの所属施設のクリニカルラダーは何段階で構成されていますか。",
      name: "institution_ladder",
      options: ["５段階", "４段階", "その他"],
    },
    {
      label: "設問#6a 所属施設で認定されている現在のあなたのクリニカルラダーを1つ選択してください。",
      name: "institution_ladder_five_level",
      options: ["レベルⅠ", "レベルⅡ", "レベルⅢ", "レベルⅣ", "レベルⅤ", "わからない"],
      condition: userData.institution_ladder === 1,
    },
    {
      label: "設問#6b 所属施設で認定されている現在のあなたのクリニカルラダーを1つ選択してください。",
      name: "institution_ladder_four_level",
      options: ["レベルⅠ", "レベルⅡ", "レベルⅢ", "レベルⅣ", "わからない"],
      condition: userData.institution_ladder === 2,
    },
    {
      label:
        "設問#7 以下の日本看護協会のクリニカルラダー基準に照らして、現在のあなたのクリニカルラダー（自己評価）として最も近いものを1つ選択してください。",
      name: "clinical_ladder",
      options: ["ラダーⅠ", "ラダーⅡ", "ラダーⅢ", "ラダーⅣ", "ラダーⅤ"],
      isTable: true,
    },
  ];

  const isNextDisabled = (() => {
    const currentStepName = steps[currentStep - 1].name;
    const isStepConditionMet =
      steps[currentStep - 1].condition === undefined || steps[currentStep - 1].condition;
    return !userData[currentStepName] && isStepConditionMet;
  })();

  const isSubmitDisabled = (() => {
    const currentStepName = steps[currentStep - 1].name;
    return !userData[currentStepName];
  })();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography component="h1" variant="h5">
            属性アンケート
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              mt: 3,
              display: "flex",
              flexDirection: "column",
              height: "600px", // Fixed height
              width: "100%", // Ensure form takes full width
            }}
          >
            <Box sx={{ flex: 1, overflowY: "auto", width: "100%" }}>
              {steps
                .filter(
                  (step, index) =>
                    index + 1 === currentStep &&
                    (step.condition === undefined || step.condition)
                )
                .map((step, index) => (
                  <Grid container spacing={2} key={index} sx={{ width: "100%", margin: 0 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ wordBreak: "break-word" }}>{step.label}</Typography>
                      {!step.isTable ? (
                        <RadioGroup
                          name={step.name}
                          value={userData[step.name] || ""}
                          onChange={(event) => handleRadioChange(event, step.name)}
                        >
                          {step.options.map((label, index) => (
                            <FormControlLabel
                              key={index}
                              value={index + 1}
                              control={<Radio />}
                              label={<Typography sx={{ wordBreak: "break-word" }}>{label}</Typography>}
                            />
                          ))}
                        </RadioGroup>
                      ) : (
                        <>
                          <Table
                            sx={{ mt: 2, mb: 2 }}
                            aria-label="simple table"
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>レベル</TableCell>
                                <TableCell>レベルの定義</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>レベル I</TableCell>
                                <TableCell>
                                  基本的な看護手順に従い必要に応じ助言を得て看護を実践する
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>レベル II</TableCell>
                                <TableCell>
                                  標準的な看護計画に基づき自立して看護を実践する
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>レベル III</TableCell>
                                <TableCell>
                                  ケアの受け手に合う個別的な看護を実践する
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>レベル IV</TableCell>
                                <TableCell>
                                  幅広い視野で予測的判断をもち看護を実践する
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>レベル V</TableCell>
                                <TableCell>
                                  より複雑な状況において、ケアの受け手にとっての最適な手段を選択しQOLを高めるための看護を実践する
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <RadioGroup
                            name={step.name}
                            value={userData[step.name] || ""}
                            onChange={(event) => handleRadioChange(event, step.name)}
                          >
                            {step.options.map((label, index) => (
                              <FormControlLabel
                                key={index}
                                value={index + 1}
                                control={<Radio />}
                                label={<Typography sx={{ wordBreak: "break-word" }}>{label}</Typography>}
                              />
                            ))}
                          </RadioGroup>
                        </>
                      )}
                    </Grid>
                  </Grid>
                ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 3,
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                onClick={handleBack}
                disabled={currentStep === 1}
                sx={{ width: "48%" }} // Set buttons to have equal width
              >
                戻る
              </Button>
              {currentStep < steps.length ? (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={isNextDisabled}
                  sx={{ width: "48%" }} // Set buttons to have equal width
                >
                  次へ
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitDisabled}
                  sx={{ width: "48%" }}
                >
                  送信
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
