import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: { token: null, quizCompleted: false },
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken } = action.payload;
      state.token = accessToken;
    },
    logOut: (state, action) => {
      state.token = null;
    },
    userCompletedQuiz: (state) => {
      state.quizCompleted = true;
    },
  },
});

export const { setCredentials, logOut, userCompletedQuiz } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.token;
export const selectQuizFinished = (state) => state.auth.quizCompleted;
