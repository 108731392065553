import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const quizQuestionQuestionsAdapter = createEntityAdapter({});

const initialState = quizQuestionQuestionsAdapter.getInitialState();

export const quizQuestionQuestionsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getQuizQuestions: builder.query({
            query: () => "/quizQuestions",
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: (responseData) => {
                const loadedQuizQuestions = responseData.map((quizQuestion) => {
                    quizQuestion.id = quizQuestion._id;
                    return quizQuestion;
                });
                return quizQuestionQuestionsAdapter.setAll(
                    initialState,
                    loadedQuizQuestions
                );
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: "Quiz", id: "LIST" },
                        ...result.ids.map((id) => ({ type: "Quiz", id })),
                    ];
                } else return [{ type: "QuizQuestion", id: "LIST" }];
            },
        }),
        addNewQuizQuestion: builder.mutation({
            query: (initialQuizQuestionData) => ({
                url: "/quizQuestions",
                method: "POST",
                body: {
                    ...initialQuizQuestionData,
                },
            }),
            onQueryStarted: async (arg, { queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    return data;
                } catch (err) {
                    console.error("Failed to add the quizQuestion:", err);
                }
            },

            // invalidatesTags: [{ type: "QuizQuestion", id: "LIST" }],
        }),
        updateQuizQuestion: builder.mutation({
            query: (initialQuizQuestionData) => ({
                url: "/quizQuestions",
                method: "PATCH",
                body: {
                    ...initialQuizQuestionData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "QuizQuestion", id: arg.id },
            ],
        }),
        deleteQuizQuestion: builder.mutation({
            query: ({ id }) => ({
                url: `/quizQuestions`,
                method: "DELETE",
                body: { id },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "QuizQuestion", id: arg.id },
            ],
        }),
    }),
});

export const {
    useGetQuizQuestionsQuery,
    useAddNewQuizQuestionMutation,
    useUpdateQuizQuestionMutation,
    useDeleteQuizQuestionMutation,
} = quizQuestionQuestionsApiSlice;

// returns the query result object
export const selectQuizQuestionsResult =
    quizQuestionQuestionsApiSlice.endpoints.getQuizQuestions.select();

// creates memoized selector
const selectQuizQuestionsData = createSelector(
    selectQuizQuestionsResult,
    (quizQuestionQuestionsResult) => quizQuestionQuestionsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllQuizQuestions,
    selectById: selectQuizQuestionById,
    selectIds: selectQuizQuestionIds,
    // Pass in a selector that returns the quizQuestionQuestions slice of state
} = quizQuestionQuestionsAdapter.getSelectors(
    (state) => selectQuizQuestionsData(state) ?? initialState
);
