import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from "react";

const Timer = forwardRef(({ overFc }, ref) => {
    const [timeElapsed, setTimeElapsed] = useState(300);
    let intervalId = null;

    useEffect(() => {
        intervalId = setInterval(() => {
            setTimeElapsed((prevTime) => prevTime - 1);
        }, 1000);

        // Cleanup the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures the effect runs only once on mount

    useEffect(() => {
        if (timeElapsed <= 0) {
            overFc();
            setTimeElapsed(300);
        }
    }, [timeElapsed]);

    const resetTimer = () => {
        setTimeElapsed(300);
    };

    useImperativeHandle(ref, () => ({
        resetTimer,
    }));

    // Convert elapsed time to minutes and seconds
    const minutes = Math.floor(timeElapsed / 60);
    const seconds = timeElapsed % 60;

    return (
        <div className="timer">
            <div className="timer__main">
                <p>残りの時間:</p>
                <p className={timeElapsed <= 30 ? 'blink' : ''}>{`${minutes}分 ${seconds}秒`}</p>
            </div>
        </div>
    );
});

export default Timer;