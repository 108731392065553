import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const quizesAdapter = createEntityAdapter({});

const initialState = quizesAdapter.getInitialState();

export const quizesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getQuizes: builder.query({
            query: () => "/quizes",
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: (responseData) => {
                const loadedQuizes = responseData.map((quiz) => {
                    quiz.id = quiz._id;
                    return quiz;
                });
                return quizesAdapter.setAll(initialState, loadedQuizes);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: "Quiz", id: "LIST" },
                        ...result.ids.map((id) => ({ type: "Quiz", id })),
                    ];
                } else return [{ type: "Quiz", id: "LIST" }];
            },
        }),
        getQuizesByUserId: builder.query({
            query: (userId) => `/quizes?user=${userId}`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: (responseData) => {
                const loadedQuizes = responseData.map((quiz) => {
                    quiz.id = quiz._id;
                    return quiz;
                });
                return quizesAdapter.setAll(initialState, loadedQuizes);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: "Quiz", id: "LIST" },
                        ...result.ids.map((id) => ({ type: "Quiz", id })),
                    ];
                } else return [{ type: "Quiz", id: "LIST" }];
            },
        }),
        getAllQuizzById: builder.query({
            query: (quizId) => `/quizes?quizid=${quizId}`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: "Quiz", id: "LIST" },
                        ...result.ids.map((id) => ({ type: "Quiz", id })),
                    ];
                } else return [{ type: "Quiz", id: "LIST" }];
            },
        }),
        addNewQuiz: builder.mutation({
            query: (initialQuizData) => ({
                url: "/quizes",
                method: "POST",
                body: {
                    ...initialQuizData,
                },
            }),
            onQueryStarted: async (arg, { queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    return data;
                } catch (err) {
                    console.error("Failed to add the quiz:", err);
                }
            },

            // invalidatesTags: [{ type: "Quiz", id: "LIST" }],
        }),
        updateQuiz: builder.mutation({
            query: (initialQuizData) => ({
                url: "/quizes",
                method: "PATCH",
                body: {
                    ...initialQuizData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Quiz", id: arg.id },
            ],
        }),
        deleteQuiz: builder.mutation({
            query: ({ id }) => ({
                url: `/quizes`,
                method: "DELETE",
                body: { id },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Quiz", id: arg.id },
            ],
        }),
        downloadQuizExcel: builder.mutation({
            query: (userID) => ({
                url: `/quizDownload?userID=${userID}`,
                method: 'GET',
                responseHandler: (response) => response.blob(), // Handle the response as a blob
            }),
            onQueryStarted: async (arg, { queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'quizzes.xlsx'); // or whatever your file name is
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                } catch (err) {
                    console.error('Failed to download the file:', err);
                }
            },
        }),
    }),
});

export const {
    useGetQuizesQuery,
    useGetQuizesByUserIdQuery,
    useGetAllQuizzByIdQuery,
    useAddNewQuizMutation,
    useUpdateQuizMutation,
    useDeleteQuizMutation,
    useDownloadQuizExcelMutation,
} = quizesApiSlice;

// returns the query result object
export const selectQuizesResult = quizesApiSlice.endpoints.getQuizes.select();

// creates memoized selector
const selectQuizesData = createSelector(
    selectQuizesResult,
    (quizesResult) => quizesResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllQuizes,
    selectById: selectQuizById,
    selectIds: selectQuizIds,
    // Pass in a selector that returns the quizes slice of state
} = quizesAdapter.getSelectors(
    (state) => selectQuizesData(state) ?? initialState
);
