import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserById, useGetUsersQuery } from "./usersApiSlice";
import QuizList from "../quiz/QuizList";

const ViewUser = () => {
    const { id } = useParams();

    // ユーザーのデータを取得するクエリを実行
    const { data: users, isLoading, isError } = useGetUsersQuery();

    // Redux state からユーザーを取得
    const user = useSelector((state) => selectUserById(state, id));

    // データがロード中かエラーが発生している場合の処理
    if (isLoading) return <p>読み込み中...</p>;
    if (isError) return <p>ユーザーデータの取得中にエラーが発生しました。</p>;

    // ユーザーが存在する場合は QuizList を表示、それ以外は「ユーザーが見つかりません」と表示
    const content = user ? <QuizList props={user} /> : <p>ユーザーが見つかりません。</p>;

    return content;
};

export default ViewUser;
