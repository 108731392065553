import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROLES } from "./config/roles";

import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";

import Layout from "./components/Layout";
import Public from "./components/Public";
import SignIn from "./features/auth/SignIn";
import DashLayout from "./components/DashLayout";
import Welcome from "./features/auth/Welcome";
import UsersList from "./features/users/UsersList";
import EditUser from "./features/users/EditUser";
import RegisterUser from "./features/users/RegisterUser";
import ViewUser from "./features/users/ViewUser";
import ImportQuestions from "./features/questions/ImportQuestions";
import QuizLayout from "./features/quiz/QuizLayout";
import Quiz from "./features/quiz/Quiz";
import ViewQuiz from "./features/quiz/ViewQuiz";
import QuizList from "./features/quiz/QuizList";
import QuestionsList from "./features/questions/QuestionsList";

function App() {
    return (
        <BrowserRouter>        
            <Routes>
                <Route path="/" element={<Layout />}>
                    {/* Public Routes */}
                    <Route index element={<Public />} />

                    
                    <Route path="login" element={<SignIn />} />
            
                    {/* Protected Routes */}
                    <Route element={<PersistLogin />}>
                        <Route
                            element={
                                <RequireAuth
                                    allowedRoles={[...Object.values(ROLES)]}
                                />
                            }
                        >
                            {/* Dash */}
                            <Route path="dash" element={<DashLayout />}>
                                <Route index element={<Welcome />} />
                                <Route path="questionsList">
                                    <Route
                                        index
                                        element={<QuestionsList />}
                                    />
                                    </Route>

                                <Route path="users">
                                    <Route
                                        index
                                        element={<UsersList />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<EditUser />}
                                    />
                                    <Route
                                        path="new"
                                        // element={<NewUserForm />}
                                        element={<RegisterUser />}
                                    />
                                    <Route path="edit">
                                        <Route
                                            path=":id"
                                            element={<EditUser />}
                                        />
                                    </Route>
                                        <Route path="view">
                                            <Route
                                                path=":id"
                                                element={<ViewUser />}
                                            />
                                        </Route>
                                    </Route>
                                    <Route path="questions">
                                        <Route
                                            index
                                            element={<ImportQuestions />}
                                        />
                                        <Route
                                            path="import"
                                            // element={<NewUserForm />}
                                            element={<ImportQuestions />}
                                        />
                                    </Route>
                                    <Route path="quizzes">
                                        <Route
                                            index
                                            element={<QuizList />}
                                        />
                                        <Route path="view">
                                            <Route
                                                path=":id"
                                                element={<ViewQuiz />}
                                            />
                                        </Route>
                                    </Route>
                                </Route>
                            {/*End Dash*/}
                            {/* Quiz */}
                            <Route path="quiz" element={<QuizLayout />}>
                                <Route index element={<Quiz />} />
                            </Route>
                        </Route>
                    {/* End Protected Routes */}
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
