import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Autocomplete } from "@mui/material";
import { Link } from "react-router-dom";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";

import { useUpdateUserMutation } from "./usersApiSlice";
import { useAddNewUserMutation } from "./usersApiSlice";

import { useNavigate } from "react-router-dom";
import { ROLES } from "../../config/roles";
import useAuth from "../../hooks/useAuth";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"© "}
      Nagoya University Hospital. All Rights Reserved.{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function RegisterUser(props) {
  const { isManager, userInstitute } = useAuth();
  const institutions = ["名古屋大学病院", "京都大学病院"];
  const navigate = useNavigate();
  const [updateUser] = useUpdateUserMutation();
  const [addNewUser, {}] = useAddNewUserMutation();
  const roles = Object.values(ROLES);
  const [role, setRole] = React.useState(
    props?.props?.role ? props?.props?.role : roles[0]
  );
  const [institute, setInstitute] = React.useState(
    props?.props?.institute ? props?.props?.institute : ""
  );
  const [isActive, setIsActive] = React.useState(props?.props?.active || false);
  const [isUniversal, setIsUniversal] = React.useState(
    props?.props?.isUniversal || false
  );
  const [sex, setSex] = React.useState(props?.props?.sex);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const userData = {};
    data.get("username") && (userData["username"] = data.get("username"));
    data.get("password") && (userData["password"] = data.get("password"));
    data.get("password2") && (userData["password2"] = data.get("password2"));
    // institute && (userData["institute"] = institute);
    data.get("institute") && (userData["institute"] = data.get("institute"));
    if (isManager) {
      userData["institute"] = userInstitute;
    }
    data.get("row-radio-buttons-group") &&
      (userData["row-radio-buttons-group"] = data.get(
        "row-radio-buttons-group"
      ));
    // data.get("active") &&
    //     (userData["active"] = data.get("active") ? true : false);
    userData["active"] = isActive;
    userData["isUniversal"] = isUniversal;
    userData["role"] = role || "受験者";
    props?.props?.id && (userData["id"] = props?.props?.id);
    props?.props?.username && (userData["username"] = props?.props?.username);

    // Validation
    if (
      !userData["username"] ||
      (!props?.props && (!userData["password"] || !userData["password2"]))
    ) {
      alert("すべての必須フィールドに入力してください。");
      return;
    }

    try {
      if (!props?.props) await addNewUser(userData);
      else await updateUser(userData);
      navigate("/dash/users");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ユーザ登録
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  autoFocus
                  id="username"
                  label="ユーザ名"
                  name="username"
                  autoComplete="username"
                  defaultValue={props?.props?.username}
                  disabled={props?.props?.username ? true : false}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="パスワード"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password2"
                  label="パスワード【確認】"
                  type="password"
                  id="password2"
                  autoComplete="new-password-2"
                />
              </Grid>
              {!isManager && (
                <Grid item xs={12}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    匿名 *
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={props?.props?.isUniversal}
                  >
                    <FormControlLabel
                      value="non-universal"
                      control={<Radio />}
                      label="いいえ"
                      checked={!isUniversal}
                      onChange={() => setIsUniversal(false)}
                    />
                    <FormControlLabel
                      value="universal"
                      control={<Radio />}
                      label="はい"
                      checked={isUniversal}
                      onChange={() => setIsUniversal(true)}
                    />
                  </RadioGroup>
                </Grid>
              )}
              {!isUniversal && !isManager && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="institutes"
                      label="所属"
                      name="institute"
                      autoComplete="institute"
                      defaultValue={props?.props?.institute}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-roles"
                      options={roles}
                      // defaultValue={roles[0]}
                      // sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="ロール" />
                      )}
                      value={role}
                      onChange={(event, newValue) => {
                        setRole(newValue);
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      // value="allowExtraEmails"
                      color="primary"
                      name="active"
                      checked={isActive}
                      onChange={() => setIsActive(!isActive)}
                    />
                  }
                  label="ユーザをデフォルトに有効にする"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              ユーザを登録する
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/">戻りたい場合はこちら</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
