import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserById, useGetUsersQuery } from "./usersApiSlice";
import EditUserForm from "./EditUserForm";

const EditUser = () => {
    const { id } = useParams();

    // Trigger the query to fetch users (if not already in the cache)
    const { data: users, isLoading, isError } = useGetUsersQuery();

    // Use selector to get the user from the Redux state
    const user = useSelector((state) => selectUserById(state, id));

    if (isLoading) return <p>読み込み中...</p>;
    if (isError) return <p>ユーザーデータの読み込み中にエラーが発生しました。</p>;

    // Show the form if the user exists, otherwise display "Loading..."
    const content = user ? <EditUserForm props={user} /> : <p>ユーザーが見つかりません。</p>;

    return content;
};
export default EditUser;
