import React from "react";
import PropTypes from "prop-types";
import "./QuizInfo.css";

function QuizInfo({ onAccept, isLoading, isError }) {
    return (
        <div className="quiz-info-container">
            <h1>注意事項</h1>
            <ul>
                <li>利用者の臨床実践能力により判定までの出題数が異なります。所要時間は概ね15～30分程度です。</li>
                <li>各問題の解答制限時間は5分です。5分以内に解答を選択し【次の問題へ】ボタンを押して次の問題へ進んでください。</li>
                <li>解答を選択しないと、【次の問題へ】ボタンを押すことはできません。</li>
                <li>解答選択後【次の問題へ】ボタンを押さずに放置すると、5分経過後その時点で選択されている選択肢を解答とし、自動的に次の問題へ進みます。</li>
                <li>5分以内に解答が選択されない場合は、不正解となり次の問題へ自動的に進みます。</li>
                <li>【次の問題へ】ボタンを押すと、前の問題に戻ることはできません。</li>
            </ul>
            <button className={!isLoading && !isError ? "start-button" : "button-disabled"} onClick={onAccept} disabled={isLoading}>
                上記内容を確認して開始する
            </button>
            {isError ? <h1 style={{ "color": "red" }}>質問に関するエラーが発生しました。管理者を連絡してください。</h1> : null}
        </div>
    );
}

QuizInfo.propTypes = {
    onAccept: PropTypes.func.isRequired,
};

export default QuizInfo;
