import { useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";
import { jwtDecode } from "jwt-decode";

const useAuth = () => {
    const token = useSelector(selectCurrentToken);
    let isManager = false;
    let isAdmin = false;
    let status = "受験者";

    if (token) {
        const decoded = jwtDecode(token);
        const { username, role, id, isUniversal, institute } = decoded.UserInfo;

        isManager = role === "マネージャー";
        isAdmin = role === "アドミン";

        if (isManager) status = "マネージャー";
        if (isAdmin) status = "アドミン";

        return { userID: id, username, role, status, isManager, isAdmin, isUniversal, userInstitute: institute };
    }

    return { username: "", role: "", isManager, isAdmin, status };
};
export default useAuth;
