import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileCirclePlus,
    faFilePen,
    faUserGear,
    faUserPlus,
    faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useSendLogoutMutation } from "../features/auth/authApiSlice";

const DASH_REGEX = /^\/dash(\/)?$/;
const NOTES_REGEX = /^\/dash\/notes(\/)?$/;
const USERS_REGEX = /^\/dash\/users(\/)?$/;

const DashHeader = () => {
    const { userID, isManager, isAdmin } = useAuth();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [sendLogout, { isLoading, isSuccess, isError, error }] =
        useSendLogoutMutation();

    useEffect(() => {
        if (isSuccess) navigate("/");
    }, [isSuccess, navigate]);

    const onNewUserClicked = () => navigate("/dash/users/new");
    const onUsersClicked = () => navigate("/dash/users");

    const onLogoutClicked = () => {
        sendLogout();
        navigate("/");
    };

    if (isLoading) return <p>Logging Out...</p>;

    if (isError) return <p>Error: {error.data?.message}</p>;

    let dashClass = null;
    if (
        !DASH_REGEX.test(pathname) &&
        !NOTES_REGEX.test(pathname) &&
        !USERS_REGEX.test(pathname)
    ) {
        dashClass = "dash-header__container--small";
    }

    let newUserButton = null;
    if (USERS_REGEX.test(pathname)) {
        newUserButton = (
            <button
                className="icon-button"
                title="New User"
                onClick={onNewUserClicked}
            >
                <FontAwesomeIcon icon={faUserPlus} />
            </button>
        );
    }

    let userButton = null;
    if (isManager || isAdmin) {
        if (!USERS_REGEX.test(pathname) && pathname.includes("/dash")) {
            userButton = (
                <button
                    className="icon-button"
                    title="Users"
                    onClick={onUsersClicked}
                >
                    <FontAwesomeIcon icon={faUserGear} />
                </button>
            );
        }
    }

    const logoutButton = (
        <button
            className="icon-button logout_button"
            title="Logout"
            onClick={onLogoutClicked}
        >
            <FontAwesomeIcon icon={faRightFromBracket} />
        </button>
    );

    const errClass = isError ? "errmsg" : "offscreen";

    let buttonContent;
    if (isLoading) {
        buttonContent = "Loading..";
    } else {
        buttonContent = (
            <>
                {newUserButton}
                {userButton}
                {logoutButton}
            </>
        );
    }

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>
            <header className="dash-header">
                <div className={`dash-header__container ${dashClass}`}>
                    <Link to="/dash" className="dash-header__title">
                        <div className="logo-title">
                            <img
                                src="/KAKENHIlogoS.jpg"
                                alt="Logo"
                                className="logo"
                            />
                            <h1>ジェネラリスト看護師の臨床実践能力判定システム</h1>
                        </div>
                    </Link>
                    <nav className="dash-header__nav">
                        {/* add more buttons later */}
                        {buttonContent}
                    </nav>
                </div>
            </header>
        </>
    );

    return content;
};

export default DashHeader;
