import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faEye } from "@fortawesome/free-solid-svg-icons";

import { useGetQuizesByUserIdQuery } from "./quizSlice";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Tooltip } from "react-tooltip";

const columns = [
    { id: "user", label: "ユーザー", minWidth: 170 },
    { id: "date", label: "日付", minWidth: 170 },
    { id: "score", label: "ラダー", minWidth: 50, maxWidth: 70 },
    {
        id: "edit",
        label: "詳細",
        minWidth: 50,
        maxWidth: 70,
    },
];
function createData(obj) {
    return {
        id: obj?._id,
        user: obj?.user?.username,
        date: new Date(obj?.date).toLocaleString("ja-JP", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        }), // Formats date and time in "YYYY/MM/DD HH:mm" format
        score: obj?.score,
    };
}

export default function QuizList(props) {
    const { isManager, isAdmin } = useAuth();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const navigate = useNavigate();
    const handleView = (quizId) => {
        if (isManager || isAdmin)
            navigate(`/dash/quizzes/view/${quizId}`);
        else
            navigate(`/profile/quiz/${quizId}`);
    }

    // Get Quizzes
    // const {
    //     data: quizzes,
    //     isLoading,
    //     isSuccess,
    //     isError,
    //     error,
    // } = useGetQuizesQuery("quizzesList", {
    //     pollingInterval: 60000,
    //     refetchOnFocus: true,
    //     refetchOnMountOrArgChange: true,
    // });
    const { data: quizzes, isError, isSuccess, isLoading, error } = useGetQuizesByUserIdQuery(props?.props?._id);

    let content;

    if (isLoading) content = <p>Loading...</p>;

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>;
    }

    if (isSuccess) {
        const { ids } = quizzes;
        let entities = Object.values(quizzes?.entities);
        let rows = entities
            ?.map((entity) => createData(entity))
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (
            event
        ) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
        };

        content = (
            <>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 900 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row) => {
                                        // Find the corresponding quiz entity by ID
                                        const quiz = entities.find(entity => entity._id === row.id);
                                        const isUnfinished = quiz && !quiz.finished;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                                className={isUnfinished ? "row--unfinished" : ""}
                                            >
                                                {columns
                                                    .slice(0, -1)
                                                    .map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                            >
                                                                {column.format && typeof value === "number"
                                                                    ? column.format(value)
                                                                    : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                <TableCell align="left">
                                                    <button
                                                        className="list__view-button"
                                                        onClick={() => handleView(row.id)}
                                                        data-tooltip-id="view-tooltip"
                                                        data-tooltip-content="ユーザーの成績を見る"
                                                        data-tooltip-place="left"
                                                    >
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </button>
                                                    <Tooltip id="view-tooltip" />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </>
        );
    }
    return content;
}
