import { useParams } from "react-router-dom";
import { useGetAllQuizzByIdQuery } from "./quizSlice";
import QuizView from "./QuizView";

const ViewQuiz = () => {
    const { id } = useParams();

    // Call the query directly using the hook
    const { data: quizzes, error, isLoading } = useGetAllQuizzByIdQuery(id);

    let content = <p>Loading...</p>;

    if (isLoading) {
        content = <p>Loading...</p>;
    } else if (error) {
        content = <p>Error: {error.message}</p>;
    } else if (quizzes) {
        content = <QuizView quiz={quizzes} />;
    } else {
        content = <p>No quizzes found for this user.</p>;
    }

    return content;
};

export default ViewQuiz;
